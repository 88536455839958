import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { Customer } from '../models/customer';
import { customerActions } from '../actions/customer.actions';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import FingerLogo from'../../../assets/images/fingerprint.jpg';
import DialogEnrolFinger from './dialog.enrol_finger';
import Swal from 'sweetalert2';
import FilePickerInHouse from '../../file_picker_in_house/FilePickerInHouse';
import { FileUpload } from '../../file_picker_in_house/models/file_upload';
import environment from '../../../environment';
import { alertActions } from '../../../auth/actions/alert.actions';
import DialogNewEnrolFinger from './dialog.new_enrol_finger';
import { User } from '../../settings/users/models/user';
import { withTranslation } from 'react-i18next';
// import {ipcMain} from 'electron';
// const { ipcRenderer } = window.require('electron');
// import { ipcMain, ipcRenderer, IpcRendererEvent } from "electron";
// const { ipcRenderer } = window.require("electron");

  function DialogCustomer(props: DialogProps<Customer>) {
    const { onClose, t} = props;
    const dispatch = useDispatch();    
    const openCustomer  = useSelector((state: any) => state.customer.openCustomer as DataDialogProps<Customer>);
    const [inputs, setInputs] = React.useState<Customer>(openCustomer?.select);
    const user:User  = useSelector((state: any) => state.authentication.user as User);
    
    const isElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
    useEffect(() => {
         setInputs(openCustomer?.select)
    }, [openCustomer]);

    const handleClose = () => {
      dispatch(customerActions.openModalCustomer({
        open : false,
        select :new Customer(),
        type : TypeDialog.add,
        loading: false
      }));
    };
   

    const onFingerSelect =  async (result: string)=>{
        if(result == "OK")        
        {
            dispatch(customerActions.getTopTenCustomers());
            handleClose();
        }
    }

    const onImagePicker =  async (result: FileUpload)=>{
        setInputs(inputs => ({ ...inputs, ["photoUri"]: result.path }));
        setInputs(inputs => ({ ...inputs, ["fileId"]: result.fileId }));
    }    

    const isNewEnrollMethodAvailable =  () => {
        var permission = user.permissions.find(x=> x.path == "btn_new_enroll_method");
        return permission;
    }

    const allowMarkAsEmployee =  () => {
        var permission = user.permissions.find(x=> x.path == "btn_add_employee");
        return permission;
    }

    const onEnrolFinger =  async ()=>{
        if(isNewEnrollMethodAvailable()){
            if(!isElectron) {
                dispatch(alertActions.error(t("actionNotAvailableOnTheWebPleaseInstallTheDesktopApp")));
                return;
            };
            
            dispatch(customerActions.openNewEnrolFinger(inputs?.customerId??"",{
                    open : true,
                    select :inputs?.customerId??"",
                    type : TypeDialog.add,
                    loading: false
                }));
            return;
        }
        dispatch(customerActions.openEnrolFinger(inputs?.customerId??"",{
                open : true,
                select :"",
                type : TypeDialog.add,
                loading: false
            }));
    }
     
    const onSyncFinger =  async ()=>{
        Swal.fire({
            title: t("doYouWantToSyncThisFingerPrintManually"),
            text: t("ifThisCustomerHaventPaidTheFingerWillRemoveAutomatically"),
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                dispatch(customerActions.syncFinger(inputs));
            }
        });
    }
    const onRemoveFinger =  async ()=>{
        Swal.fire({
            title: t("doYouWantToRemoveThisFingerPrintManually"),
            text:  t("ifThisCustomerHaventPaidTheFingerWillRemoveAutomatically"),
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                dispatch(customerActions.removeFinger(inputs));
            }
        });
    }
    const onRemoveFingerPermanent =  async ()=>{
        Swal.fire({
            title: t("doYouWantToRemovePermanently"),
            text:  t("willRemoveTheFingerPrintFromTheClockAndYouWillNeedToAddedAgain"),
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                dispatch(customerActions.removeFingerPermanent(inputs));
            }
        });
    }


    const onMarkAsEmployee =  async ()=>{
        if(!allowMarkAsEmployee()) return;
        Swal.fire({
            title: t("doYouWantToMarkThisCustomerAsEmployee"),
            text:  t("thisActionWillPreventTheSystemRemoveTheFingerPrintAutomatically"),
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                dispatch(customerActions.markAsEmployee(inputs));
            }
        });
    }
    const onRemoveAsEmployee =  async ()=>{
        if(!allowMarkAsEmployee()) return;
        Swal.fire({
            title: t("doYouWantToRemoveThisEmployee"),
            text: t("thisActionWillConvertThisEmployeeToCustomer"),
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                dispatch(customerActions.unmarkAsEmployee(inputs));
            }
        });
    }
    
    function handleSubmit(e:any) {
        e.preventDefault();
        if (inputs.name && inputs.dni && inputs.phone) {
            if(openCustomer?.type ===TypeDialog.add){
                dispatch(customerActions.add(inputs));
            }
            if(openCustomer?.type ===TypeDialog.edit){
               dispatch(customerActions.edit(inputs));
            }
        }
    }

    function handleChange(e:any) {
        const { name, value } = e.target;

        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleChangebirthday(birthday:any) {
        setInputs(inputs => ({ ...inputs, ['birthday']: birthday }));
    }

    const footer = (
        <div>
           <Button loading={openCustomer.loading} disabled={openCustomer.loading} label={t("save")} onClick={handleSubmit} icon="pi pi-save"/>
        </div>
    );
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const getBirthDay = (date?:Date)=>{
      if(date == null || !date ) return  undefined;
      let dateFormat = new Date(date!);
      return dateFormat;
  }



     
    return (
        <Dialog  header={openCustomer.type  == TypeDialog.add? t("add"): t("customer") + ": " + (inputs.name || "")}  footer={footer} visible={openCustomer?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid'>   

                <div className="col-12">
                    <div className="grid">
                        <div className="col-8 md:col-9">
                            <div className="grid">
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="customerId" className="block">{t("id")}</label>
                                    <InputText id="customerId" disabled={true} value={inputs.customerId || ""} readOnly={true} className="block"/>
                                    {/* <small id="username1-help" className="block">Ingrese el No</small> */}
                                </div>
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="dni" className="block">{`${t('dni')}/${t('phone')}`}</label>
                                    <InputText id="dni" name="dni" keyfilter="int" value={inputs.dni || ""} onChange={handleChange}  className={(!inputs?.dni) ? "block p-invalid":"block"}/>
                                    {(!inputs?.dni) ?<small id="dni-help" className="block p-error">{t("dniRequired")}</small>: <></> }
                                </div>
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="rtn"  className="block">{t("rtn")}</label>
                                    <InputText id="rtn" name="rtn" keyfilter="int" value={inputs.rtn || ""} onChange={handleChange} className="block"/>
                                </div>
                                <div className="field col-6 md:col-6">
                                    <label htmlFor="name" className="block">{t("name")}</label>
                                    <InputText id="name" name="name" value={inputs.name || ""} onChange={handleChange} className={(!inputs?.name) ? "block p-invalid":"block"}/>
                                    {(!inputs?.name) ?<small id="name-help" className="block p-error">{t("nameRequired")} </small>: <></> }
                                </div>
                                <div className="field col-6 md:col-6">
                                    <label htmlFor="birthday" className="block">{t("birthDay")}</label>
                                    <Calendar id="birthday"  value={getBirthDay(inputs.birthday)} onChange={(e) => handleChangebirthday(e.value)} locale="es"  dateFormat='dd/mm/yy'/>
                                </div>
                                <div className="field col-6 md:col-8">
                                    <label htmlFor="email" className="block">{t("email")}</label>
                                    <InputText id="email" name="email" value={inputs.email || ""} onChange={handleChange} className="block"/>
                                </div>
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="phone" className="block">{t("phone")}</label> 
                                    <InputText id="phone" name="phone" value={inputs?.phone || ""} onChange={handleChange} className={(!inputs?.phone) ? "block p-invalid":"block"}/>
                                    {(!inputs?.phone) ?<small id="phone-help" className="block p-error">{t("phoneRequired")}</small>: <></> }
                                </div>


                                { 
                                    openCustomer?.type ===TypeDialog.edit ?
                                    (inputs.fingerPrint && inputs.fingerPrintSize)?
                                    <div className="field col-12">
                                        <img src={FingerLogo} width="50px" alt="" />
                                        <p><strong>{t('assignedFingerPrint')}</strong></p>
                                        <Button disabled={openCustomer.loading} style={{width: "120px"}} icon="pi pi-sync" label={t("sync")} onClick={onSyncFinger} className="p-button p-button-outlined" />
                                        <Button disabled={openCustomer.loading} style={{width: "120px"}} icon="pi pi-trash" label={t("remove")} onClick={onRemoveFinger} className="p-button-danger p-button-outlined" />
                                        <Button disabled={openCustomer.loading} style={{width: "180px"}} icon="pi pi-times-circle" label={t("removeFingerPrint")} onClick={onRemoveFingerPermanent} className="p-button-danger p-button-outlined" />
                                    </div>
                                    :
                                    <div className='col-12 md:col-12'>
                                       <Button disabled={openCustomer.loading} style={{width: "180px"}} icon="pi pi-id-card" label={t("enrollFingerPrint")} onClick={onEnrolFinger} className="p-button-danger" />
                                    </div>
                                     :<div></div>
                                }
                                {openCustomer?.type ===TypeDialog.edit ?
                                    <div className="field col-12 md:col-12">
                                        <span className="p-float-label p-input-icon-left">
                                            <Checkbox inputId="cbActive" name="active" value="Activo" onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                            <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                                        </span>
                                    </div>
                                    :<div></div>
                                }

                                {
                                     openCustomer?.type ===TypeDialog.edit &&
                                     (inputs.fingerPrint && inputs.fingerPrintSize) && 
                                     
                                     allowMarkAsEmployee()? 
                                    <div className="field col-12">
                                        {
                                            !inputs.isEmployee  ? 
                                            <Button disabled={openCustomer.loading} style={{width: "220px"}} icon="pi pi-user-plus" label={t("markAsEmployee")} onClick={onMarkAsEmployee}  severity="secondary" outlined  />
                                            :
                                            <Button disabled={openCustomer.loading} style={{width: "220px"}} icon="pi pi-times-circle" label={t("convertToCustomer")} onClick={onRemoveAsEmployee} className="p-button-danger p-button-outlined" />
                                        }
                                    </div>
                                    : <div></div>
                                }
                            </div>
                        </div>
                        <div className="col-4 md:col-3">
                            <FilePickerInHouse onClose={onImagePicker} photoUri={inputs.photoUri??""}></FilePickerInHouse>   
                        </div>
                    </div>
                </div>
                <DialogEnrolFinger onClose={onFingerSelect} ></DialogEnrolFinger>        
                <DialogNewEnrolFinger onClose={onFingerSelect} ></DialogNewEnrolFinger>        
            </div>
        </Dialog>
    );
  }
    
  export default withTranslation()(DialogCustomer);