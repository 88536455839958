import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { alertActions } from '../../../../auth/actions/alert.actions';
import { appUserActions } from '../actions/app-user.actions';
import { AppUser } from '../models/app-user';

  function DialogAppRestartPassword() {
    const dispatch = useDispatch();    
    const openAppRestart  = useSelector((state: any) => state.appUsers.openAppRestart as DataDialogProps<AppUser>);
    const [inputs, setInputs] = React.useState<AppUser>(openAppRestart?.select);

    useEffect(() => {
         setInputs(openAppRestart?.select)
    }, [openAppRestart]);

    const handleClose = () => {
      setInputs(new AppUser())
      dispatch(appUserActions.openModalRestart({
        open : false,
        select : new AppUser(),
        type : 0,
        loading: false
      }));
    };
  

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    function sendLinkEmail(){
        dispatch(appUserActions.sendLinkEmail(openAppRestart.select.userId!));
    }  


    const footer = (
        <div>
            <Button disabled={openAppRestart.loading} onClick={sendLinkEmail} label="Mandar por correo" className="p-button-secondary" icon="pi pi-id-card"/>
        </div>
    );

    return (
        <Dialog header="Restablecer contraseña" footer={footer} visible={openAppRestart?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>
                {openAppRestart.loading ? <SkeletonComponent/>:
                <div className="p-fluid grid mt-3">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                 <i className="pi pi-search" />
                                <InputText id="email" disabled name="email" value={inputs.email} onChange={handleChange} />
                                <label htmlFor="email">Correo</label>
                            </span>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText id="email" disabled name="email" value={inputs.email} onChange={handleChange} />
                                <label htmlFor="email">Correo Electronico</label>
                            </span>
                        </div>
                         {/* <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText id="phone" disabled name="phone" value={inputs.phone} onChange={handleChange} />
                                <label htmlFor="phone">Celular</label>
                            </span>
                        </div> */}
                </div>
            }
            </div>
        </Dialog>
    );
  }
    
  export { DialogAppRestartPassword };