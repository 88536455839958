import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../models/user';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { Dialog } from 'primereact/dialog';
import { userActions } from '../actions/auth.actions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { roleActions } from '../../roles/actions/role.actions';
import { Role } from '../../roles/models/role';
import { Checkbox } from 'primereact/checkbox';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { alertActions } from '../../../../auth/actions/alert.actions';
import { Store } from '../models/store';
import { DocumentSize } from '../models/document_size';
import { TypeDialog } from '../../../../helpers/type_dialog';
import { Langs } from '../models/langs';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { languages } from '../../../../helpers/consts';

  function DialogUser({t}: WithTranslation) {
    const dispatch = useDispatch();    
    const openUser  = useSelector((state: any) => state.users.openUser as DataDialogProps<User>);
    const roles: Role[] = useSelector((state:any) => state.roles.roles as Role[]);
    const stores: Store[] = useSelector((state:any) => state.roles.stores as Store[]);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<User>(openUser?.select);
    const [submitted, setSubmitted] = React.useState(false);
    

    const documentSizes = [ new DocumentSize({ documentSize:1, name:t("pos") }), new DocumentSize({ documentSize:2, name:t("letter") })]
  
    useEffect(() => {
        setInputs(openUser?.select)
        setSubmitted(false)
    }, [openUser]);

    const handleClose = () => {
      setInputs(new User())
      dispatch(userActions.openModalUser({
        open : false,
        select : new User(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };

    useEffect(() => { 
        dispatch(roleActions.getRoles()); 
        dispatch(roleActions.getStores()); 
    },[]);

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.userName && inputs.name && inputs.email && inputs.roleId !==undefined && inputs.storeId && inputs.documentSize) {
          if(openUser?.type ===0 && inputs.password){
            dispatch(userActions.add(inputs));
          }
          if(openUser?.type ===1){
            dispatch(userActions.edit(inputs));
          }
      }
    }

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const footer = (
        <div>
            <Button onClick={handleSubmit} label={openUser?.type ===0 ? t("add"): t("edit")} icon="pi pi-check"/>
        </div>
    );

   
    return (
        <Dialog header={openUser?.type ===0 ?t("add"): t("edit")} position="top" footer={footer} visible={openUser?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>
                {openUser.loading ? <SkeletonComponent/> :
                <div className="p-fluid grid mt-3">

                    <div className="field col-6 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="name" name="name" value={inputs.name} onChange={handleChange}  className={(!inputs?.name) ? "block p-invalid":"block"}  />
                            <label htmlFor="name">{t("name")}</label>
                        </span>
                        {(!inputs?.name) ?<small id="name-help" className="block p-error">{t("nameRequired")}</small>: <></> }
                    </div>
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="userName" name="userName" value={inputs.userName} onChange={handleChange} className={(!inputs?.userName) ? "block p-invalid":"block"}  />
                            <label htmlFor="userName">{t("userName")}</label>
                        </span>
                        {(!inputs?.userName) ?<small id="userName-help" className="block p-error">{t("userNameRequired")}</small>: <></> }
                    </div>
                     <div className="field col-6 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="password" name="password"  value={inputs.password} onChange={handleChange} className={(!inputs?.password && openUser.type == TypeDialog.add) ? "block p-invalid":"block"}  />
                            <label htmlFor="password">{t("password")}</label>
                        </span>
                        {(!inputs?.password && openUser.type == TypeDialog.add) ?<small id="password-help" className="block p-error">{t("passwordRequired")}</small>: <></> }
                    </div>
                    <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText id="email" name="email" value={inputs.email}
                                    onChange={handleChange} className={(!inputs?.email) ? "block p-invalid":"block"}  />
                                <label htmlFor="email">{t("email")}</label>
                            </span>
                            {(!inputs?.email) ?<small id="email-help" className="block p-error">{t("emailRequired")}</small>: <></> }
                    </div>
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownRol" name="roleId" value={inputs.roleId} options={roles}
                             onChange={handleChange} optionLabel="name" optionValue="roleId"
                            className={(!inputs?.roleId) ? "p-invalid":""} />
                            <label htmlFor="dropdownRol">{t("role")}</label>
                        </span>
                        {(!inputs?.roleId) ?<small id="roleId-help" className="block p-error">{t("roleRequired")}</small>: <></> }
                    </div> 
                     
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownStore" name="storeId" value={inputs.storeId} options={stores} 
                            onChange={handleChange} optionLabel="name" optionValue="storeId" 
                            className={(!inputs?.storeId) ? "p-invalid":""}/>
                            <label htmlFor="dropdownStore">{t("office")}</label>
                        </span>
                        {(!inputs?.storeId) ?<small id="storeId-help" className="block p-error">{t("officeRequired")}</small>: <></> }
                    </div> 
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownDocumentSize" name="documentSize" value={inputs.documentSize} options={documentSizes}
                             onChange={handleChange} optionLabel="name" optionValue="documentSize" 
                            className={(!inputs?.documentSize) ? "p-invalid":""}/>
                            <label htmlFor="dropdownDocumentSize">{t("documentSize")}</label>
                        </span>
                        {(!inputs?.documentSize) ?<small id="documentSize-help" className="block p-error">{t("documentSizeRequired")}</small>: <></> }
                    </div> 
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownLang" name="lang" value={inputs.lang} options={languages}
                             onChange={handleChange} optionLabel="name" optionValue="lang" 
                            className={(!inputs?.lang) ? "p-invalid":""}/>
                            <label htmlFor="dropdownLang">{t("language")}</label>
                        </span>
                        {(!inputs?.lang) ?<small id="documentLang-help" className="block p-error">{t("languageRequired")}</small>: <></> }
                    </div> 
                  
                
                    {
                        openUser.type == 1?
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <Checkbox inputId="cbActive" name="active" value={t("active")} onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                            </span>
                        </div>
                        : <div></div>
                    }
              


                </div>
                }
            </div>
        </Dialog>
    );
  }
    
  export default withTranslation()(DialogUser);