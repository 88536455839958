import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'react-pivottable/pivottable.css';
import DialogPrintPdf from '../print-pdf/components/dialog.print-pdf';
import { suscriptionActions } from '../suscriptions/actions/suscription.actions';
import { DataDialogProps } from '../../models/data.dialog.props';
import { getDefaultDate } from '../../helpers/format';
import { withTranslation, WithTranslation } from 'react-i18next';

function DiaryClosePage({t}: WithTranslation) {
    const [fromDate, setFromDate] = React.useState<Date >(getDefaultDate());
    const openDiaryClose  = useSelector((state: any) => state.suscription.openDiaryClose as DataDialogProps<number>);
    const [submit, setSubmit] = React.useState<boolean>(false);   
    const dispatch = useDispatch();

    const handSearch = () => {
        setSubmit(true)
        dispatch(suscriptionActions.printDiaryClose(fromDate?.toISOString() ??"" ));
        // dispatch(reportActions.searchDiarySales(fromDate?.toISOString() ?? "", toDate?.toISOString()??""));
    }

   function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

 
    return (
        <div className="card">
            <div className="p-fluid grid">
                <div className="field col-2">  
                    <label htmlFor="fromDate" className="block">{t("date")}</label>
                    <Calendar id="fromDate" locale="es"  dateFormat='dd/mm/yy' disabled={openDiaryClose.loading} value={fromDate!== undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                    {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }           
                </div>
               
                <div className=" col-2 mt-5">
                    <Button label="Generar" disabled={openDiaryClose.loading || !fromDate} loading={openDiaryClose.loading} onClick={handSearch}  icon="pi pi-search" iconPos="left"  />
                </div>
                <DialogPrintPdf expand onClose={()=>{}}/>
            </div>
        </div>
    );
}

    
export default withTranslation()(DiaryClosePage);

