import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { Button } from 'primereact/button';
import { User } from '../settings/users/models/user';
import { Bill } from './models/bill';
import { billsActions } from './actions/bills.actions';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import { format, getDefaultDate } from '../../helpers/format';
import { WithTranslation, withTranslation } from 'react-i18next';


function ListBillPage({t}: WithTranslation) {
    const bills: Bill[]= useSelector((state:any) => state.bills.bills);
    const loading: boolean= useSelector((state:any) => state.bills.loading);
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const user:User  = useSelector((state: any) => state.authentication.user as User);
    const [fromDate, setFromDate] = useState<Date>(getDefaultDate());
    const [toDate, setToDate] = useState<Date>(getDefaultDate());
 

    useEffect(()=>{
        handSearch()
    },[])
    const handSearch = ()=>{
        dispatch(billsActions.getBills(fromDate?.toISOString() ?? "", toDate?.toISOString()?? "")); 
    }
    function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

   function handleToDate(e:any) {
        const {value } = e.target;
        setToDate(value);
   }
    const addBill = ()=>{
        navigate(`/bill`)
    }

    const canAddBill = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_add_bill");
        return permission;
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div>
                    <h5 className="m-0">{t("billsList")}</h5>  
                    <div>
                    <div className="p-fluid grid">
                        
                            <div className="field col-4">  
                                <label htmlFor="fromDate" className="block">{t("fromDate")}</label>
                                <Calendar id="fromDate" value={fromDate!= undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate} locale="es"  dateFormat='dd/mm/yy'></Calendar>
                                {/* {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }            */}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="toDate" className="block">{t("toDate")}</label>
                                <Calendar id="toDate" value={toDate!= undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate} locale="es"  dateFormat='dd/mm/yy'></Calendar>
                                {/* {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> } */}
                            </div>
                            <div className="field col-4 mt-5">
                                  <Button label={t("search")} onClick={handSearch} icon="pi pi-search" iconPos="left"  />
        
                            </div>
                        </div>

                    </div>
                </div>

                <span className="p-input-icon-left mt-5">
                    <Button label={t("addNew")}  disabled={!canAddBill() || loading}  onClick={addBill} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }
    const header = renderHeader();

    const createBodyTemplate = (row:Bill) => {
        if(row.createdDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.createdDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const totalBodyTemplate = (row:Bill) => {
        return <span>L. {format(row.total??0,2)}</span>
    }

      const actionBodyTemplate = (bill:Bill) => {
        const onUpdateBill =()=>{
            navigate(`/bill/${bill.billId}`)
        }
        return <span className="p-buttonset">
            <Button label={t("check")} className="p-button p-button-outlined" icon="pi pi-pencil" iconPos="left" onClick={onUpdateBill} />
        </span>
    }
     
      const statusBodyTemplate = (bill:Bill) => {
        return <Badge value={bill.statusId == 1? t("active") : t("canceled")} severity={bill.statusId == 1? undefined: "danger"} ></Badge>
    }

    return (

            <div className="grid  mt-1">
                    <div className=" col-12 card">
                    {header}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={bills??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="billId" header={t("id")}></Column>
                        <Column filter filterType="contains" field="description" header={t("description")} ></Column>
                        <Column filter filterType="contains" field="createdByName" header={t("createdByName")} ></Column>
                        <Column sortable  field="createDate" header={t("date")} body={createBodyTemplate}></Column>
                        <Column filter filterType="contains" field="total" header={t("total")} body={totalBodyTemplate}></Column>
                        <Column header={t("status")} body={statusBodyTemplate} />
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                </div>

            </div>
   
       
    );
}

export default withTranslation()(ListBillPage);